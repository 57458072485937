import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { take } from "rxjs/operators";
import { Rechtstext } from "../../models/admin/rechtstext.model";
import { Bauteil } from "../../models/business/bauteil.model";
import { BauteilTypes } from "../../models/enums/bauteil-types.enum";
import { ConfigService } from "../config.service";
import { StorageService } from "../storage.service";

@Injectable({
  providedIn: "root",
})
export class BauteilApiService {
  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private storage: StorageService,
  ) {

  }

  public getByTyp(typ: BauteilTypes): Observable<any> {
    var subject = new Subject<any>();
    this.http
      .get<any>(this.config.ApiUrl + "/bauteil" + "/typ/", {
        params: {
          typ: typ.toString()
        },
        observe: "response",
        responseType: "json",
      })
      .pipe(take(1))
      .subscribe(
        (result) => {
          if(result.ok)
          {
            console.log(result);
            console.log(this.config.App);
            console.log(result.body);
            var list: Bauteil[] = [];
            result.body.forEach(e => {
              list.push(Object.assign(new Bauteil(), e))
            });
            subject.next(list);
          }
          else
            subject.next(null);
        },
        (error) => {
          console.log("Error", error);
          subject.next(null);
        }
      );

    return subject.asObservable();
  }
}
