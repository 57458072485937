export class EasyBillDocumentPosition {
    number: string
    description: string
    document_note: string
    quantity: number
    quantity_str: string
    unit: string
    type: string
    position: number
    single_price_net: number
    single_price_gross: number
    vat_percent: number
    discount: number
    discount_type: string
    position_id: number
    total_price_net: number
    total_price_gross: number
    total_vat: number
    serial_number_id: string
    serial_number: string
    booking_account: string
    export_cost_1: string
    export_cost_2: string
    cost_price_net: number
    cost_price_total: number
    cost_price_charge: number
    cost_price_charge_type: string
    itemType: string
    sale_price: number
    id: number
  }