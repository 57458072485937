import { Injectable } from "@angular/core";
import { HammerGestureConfig } from "@angular/platform-browser";
import * as Hammer from 'hammerjs';

@Injectable()
export class HammerJSConfigService extends HammerGestureConfig {
    overrides = <any> {
        swipe: { direction: Hammer.DIRECTION_ALL },
        pinch: { enable: false}
    };

    // buildHammer(element: HTMLElement) {
    //     let mc = new Hammer(element, {
    //       touchAction: "auto",
    //     });
    //     mc.get('swipe').set({direction: Hammer.DIRECTION_ALL})
    //     return mc;
    //   }
}