import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { take } from "rxjs/operators";
import { Rechtstext } from "../../models/admin/rechtstext.model";
import { Bauteil } from "../../models/business/bauteil.model";
import { BestellungDTO } from "../../models/dto/bestellung.dto";
import { EasyBillDocument } from "../../models/easybill/EasyBillDocument";
import { EasyBillDocumentPosition } from "../../models/easybill/EasyBillDocumentPosition";
import { BauteilTypes } from "../../models/enums/bauteil-types.enum";
import { ConfigService } from "../config.service";
import { CryptoService } from "../crypto.service";
import { StorageService } from "../storage.service";

@Injectable({
  providedIn: "root",
})
export class EasyBillApiService {
  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private storage: StorageService,
  ) {

  }

  public getPositionByNumber(number: string): Observable<any> {
    var subject = new Subject<any>();
    this.http
      .get<any>(this.config.ApiUrl + "/easybill/positions" + "/number/", {
        params: {
          number: number
        },
        observe: "response",
        responseType: "json",
      })
      .pipe(take(1))
      .subscribe(
        (result) => {
          if(result.ok)
          {
            var r = Object.assign(new EasyBillDocumentPosition(), result.body)
            subject.next(r);
          }
          else
            subject.next(null);
        },
        (error) => {
          console.log("Error", error);
          subject.next(null);
        }
      );

    return subject.asObservable();
  }


  public createDocument(bestellung: BestellungDTO): Observable<any> {
    var subject = new Subject<any>();

    var model: any = {}

    model.kontaktdaten = CryptoService.Encrypt(this.replaceUmlaut(JSON.stringify(bestellung.kontaktdaten)))

    model.warenkorbId = CryptoService.Encrypt(this.replaceUmlaut(JSON.stringify(bestellung.warenkorbId)))
    model.zahlungsmittel = bestellung.zahlungsmittel
    model.app = this.config.AppType

    this.http
      .post<any>(this.config.ApiUrl + "/easybill/documents", model, {
        observe: "response",
        responseType: "json",
      })
      .pipe(take(1))
      .subscribe(
        (result) => {
          var resultModel = Object.assign(new EasyBillDocument(), result.body);
          console.log(result);
          subject.next(resultModel);
        },
        (error) => {
          console.log("Error", error);
          subject.next(null);
        }
      );

    return subject.asObservable();
  }

  private replaceUmlaut(input: any): String {
 
    //replace all lower Umlauts
    var output = input.replace(new RegExp("ü", 'g'), "ue")
                      .replace(new RegExp("ö", 'g'), "oe")
                      .replace(new RegExp("ä", 'g'), "ae")
                      .replace(new RegExp("ß", 'g'), "ss");

    //first replace all capital umlaute in a non-capitalized context (e.g. Übung)
    output = output.replace(new RegExp("Ü(?=[a-zäöüß ])", 'g'), "Ue")
                   .replace(new RegExp("Ö(?=[a-zäöüß ])", 'g'), "Oe")
                   .replace(new RegExp("Ä(?=[a-zäöüß ])", 'g'), "Ae");

    //now replace all the other capital umlaute
    output = output.replace(new RegExp("Ü", 'g'), "UE")
                   .replace(new RegExp("Ö", 'g'), "OE")
                   .replace(new RegExp("Ä", 'g'), "AE");

    return output;  
  }
}
