import { Base } from "../base/base.model";
import { Unternehmen } from "../business/unternehmen.model";

export class Rechtstext extends Base {
    typ: string;
    subject: string;
    zuletzt_aktualisiert: Date;
    inhalt: string;

    unternehmen: Unternehmen;
}
