import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ShopComponent } from './shop/shop.component';
import { PagesComponent } from './pages/pages.component';
import { ElementsComponent } from './elements/elements.component';
import { WatchinatorIndexComponent } from './watchinator/watchinator-index/watchinator-index.component';
import { WatchinatorComponent } from './watchinator/watchinator.component';
import { RechtstexteComponent } from './rechtstexte/rechtstexte.component';
import { AccountComponent } from './account/account.component';
import { GalerieComponent } from './galerie/galerie.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'shop',
    component: ShopComponent,
    loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule)
  },
 
  { 
    path: 'p',
    component: PagesComponent,
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) 
  },
  { 
    path: 'elements', 
    component: ElementsComponent,
    loadChildren: () => import('./elements/elements.module').then(m => m.ElementsModule) },
  {
    path: 'watchinator',
    component: WatchinatorComponent,
    loadChildren: () => import('./watchinator/watchinator.module').then(m => m.WatchinatorModule)
  },
  {
    path: 'galerie',
    component: GalerieComponent,
    loadChildren: () => import('./galerie/galerie.module').then(m => m.GalerieModule)
  },
  {
    path: 'account',
    component: AccountComponent,
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'rechtstexte',
    component: RechtstexteComponent,
    loadChildren: () => import('./rechtstexte/rechtstexte.module').then(m => m.RechtstexteModule)
  },
  {
    path: '**', // Navigate to Home Page if not found any page
    redirectTo: 'home',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    useHash: false,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
