import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { umask } from "process";
import { Observable, Subject } from "rxjs";
import { take } from "rxjs/operators";
import { WarenkorbProdukt } from "../models/business/warenkorb-produkt.model";
import { Warenkorb } from "../models/business/warenkorb.model";
import { ApplicationTypes } from "../models/enums/application-types.enum";
import { LocalStorageTypes } from "../models/enums/localstorage.enum";
import { WarenkorbProduktTypes } from "../models/enums/warenkorb-produkt-types.enum";
import { WarenkorbApiService } from "./api/warenkorb.api";
import { CryptoService } from "./crypto.service";
import { StorageService } from "./storage.service";


const state = {
  warenkorb: new Warenkorb()  
}

@Injectable({
  providedIn: "root",
})
export class WarenkorbService {

  private warenkorbSubject = new Subject<any>()

  constructor(private api: WarenkorbApiService, private storage: StorageService, private router: Router)
  {
      this.getWarenkorbAsync().then((e: Warenkorb) => {
        if(e != null)
         {
          state.warenkorb = e
          this.warenkorbSubject.next(e)
         }
         this.subcribeRouter()
      })
  }

  // Lädt Warenkorb bei jedem Routen-Wechsel.
  private subcribeRouter() {
    this.router.events.subscribe(async (val) => {
      if(val instanceof NavigationEnd && state.warenkorb != null)
      {
        await this.getWarenkorbAsync()
      }
    });
  }

  // Fügt ein Produkt zum Warenkorb hinzu.
  public async addToWarenkorbAsync(product: WarenkorbProdukt, redirect: boolean = false) {
    var r = new Promise((resolve, reject) => {
      if(this.storage.getWarenkorbId() == null && state.warenkorb == null)
      {
        state.warenkorb = new Warenkorb()
        state.warenkorb.products = []
      }

      
      state.warenkorb.products.push(product)
      state.warenkorb = this.prepareWarenkorb(state.warenkorb)

      this.saveWarenkorbAsync().then((r) => {
          console.log("addToWarenkorb Serverside result", r)
          if(redirect)
            this.router.navigate(['shop/checkout'])
          this.warenkorbSubject.next(state.warenkorb)
          resolve(true)
      }, error => {
        console.log(error)
        resolve(false)
      })
    });

    return r;
  }

  // Entfernt ein Produkt aus dem Warenkorb.
  public async removeFromWarenkorbAsync(product: WarenkorbProdukt) {
    var r = new Promise((resolve, reject) => {
      if(state.warenkorb == null)
      {
        state.warenkorb = new Warenkorb()
        state.warenkorb.products = []
      }
      else {
        if(product.type == WarenkorbProduktTypes.VERSAND) {
          return;
        }
        console.log(state.warenkorb.products.indexOf(product))
        if(state.warenkorb.products.indexOf(product) >= 0)
        {
          state.warenkorb.products.splice(state.warenkorb.products.indexOf(product), 1)
          state.warenkorb = this.prepareWarenkorb(state.warenkorb)
        }
      }

      this.saveWarenkorbAsync().then(() => {
        this.warenkorbSubject.next(state.warenkorb)
        resolve(true)
      })
    });

    return r;
  }

  // Entfernt den Versand aus dem Warenkorb.
  public async removeVersandAsync() {
    var r = new Promise(async (resolve, reject) => {
      state.warenkorb.products = state.warenkorb.products.filter(e => e.type != WarenkorbProduktTypes.VERSAND)
      await this.saveWarenkorbAsync().then(
        e => { resolve(e)}
      )
    })

    return r
  }

  // Liest den Warenkorb aus.
  public async getWarenkorbAsync() {
    var id = this.storage.getWarenkorbId()
    var r = new Promise((resolve, reject) => {
      if(id == null)
      {
        state.warenkorb = new Warenkorb()
        // Erstellt einen neuen Warenkorb, falls noch keiner existiert.
        this.saveWarenkorbAsync().then((e: Warenkorb) => {
          if(e != null)
          {
            this.storage.setWarenkorbId(e.id.toString())
            state.warenkorb = e
            this.warenkorbSubject.next(e)
            resolve(e)
          }
        })
      }
      else {
        this.api.getById(id).pipe(take(1)).subscribe(e => {
          if(e != null)
          {
            this.setWarenkorb(e)
            this.warenkorbSubject.next(e)
            resolve(state.warenkorb)
          }
          else {
            // Falls eine id, aber kein Warenkorb existiert, wird der Warenkorb resettet.
            this.storage.resetWarenkorb()
            state.warenkorb = new Warenkorb()
            this.saveWarenkorbAsync().then(re => {
              this.warenkorbSubject.next(e)
              resolve(re)
            })
          }
        }, err => { 
          console.log(err)
          this.resetWarenkorbAsync().then(e => {
            this.warenkorbSubject.next(e)
            resolve(e)
          })
        
        })
      }
    })

    return r
  }

  // Setzt den Warenkorb zurück.
  public async resetWarenkorbAsync() {
    var r = new Promise((resolve, reject) => {
      this.storage.resetWarenkorb()
      state.warenkorb = new Warenkorb()
      this.saveWarenkorbAsync().then(re => {
        resolve(re)
      })
    })
    return r
  }

  // Löscht den Warenkorb.
  public deleteWarenkorb() {
    var id = this.storage.getWarenkorbId()
    if(id != null)
    {
      this.api.deleteById(id).pipe(take(1)).subscribe(e => {
        this.storage.setWarenkorbId(null)
        this.getWarenkorbAsync()
      }, err => { console.log(err) })
    }
  }

  // Speichert den Warenkorb. 
  public async saveWarenkorbAsync() {
    var r = new Promise((resolve, reject) => {
      state.warenkorb = this.prepareWarenkorb(state.warenkorb)
      this.api.save(state.warenkorb).pipe(take(1)).subscribe(e => {
        if(e != null)
        {
          this.storage.setWarenkorbId(e.id)
          this.setWarenkorb(e)
          this.warenkorbSubject.next(e)
          resolve(e)
        }
        else
        {
          resolve(null)
        }
      }, err => { console.log(err) })
    })

    return r
  }

  // Markiert den Warenkorb als "bestellt". 
  public async doneWarenkorbAsync() {
    var r = new Promise((resolve, reject) => {
      if(this.storage.getWarenkorbId() != null && this.storage.getWarenkorbId() != "")
      {
        this.api.done(this.storage.getWarenkorbId().toString()).pipe(take(1)).subscribe(e => resolve(e), err => { console.log(err) })
      }
      else
        resolve(false)
    })

    return r
  }


  // Wandelt das Datenbank-Objekt in einen Warenkorb um.
  public setWarenkorb(warenkorb: Warenkorb) {
    if(warenkorb.encryptedWarenkorbJSON != null && warenkorb.encryptedWarenkorbJSON != "")
    {
      warenkorb.products = JSON.parse(CryptoService.Decrypt(warenkorb.encryptedWarenkorbJSON))
    }
    else {
      warenkorb.products = []
    }

    state.warenkorb = warenkorb
    this.warenkorbSubject.next(warenkorb)
    console.log("WARENKORB", state.warenkorb)
  }

  // Bereitet den Warenkorb für die Datenbank vor.
  private prepareWarenkorb(warenkorb: Warenkorb) {
    warenkorb.encryptedWarenkorbJSON = CryptoService.Encrypt(JSON.stringify(warenkorb.products))
    this.warenkorbSubject.next(warenkorb)
    state.warenkorb = warenkorb
    return warenkorb
  }


  public warenkorbObserver(): Observable<Warenkorb> {
    return this.warenkorbSubject.asObservable()
  }

  public getSumme(ignoreVersand: boolean = false) {
    var summe = 0
    if(state.warenkorb != null  && state.warenkorb.products != null)
    {
      state.warenkorb.products.forEach(e => {
        if(e.easybill_position != null)
        {
          if(ignoreVersand && e.type == WarenkorbProduktTypes.VERSAND) {

          }
          else {
            summe += e.easybill_position.sale_price * e.quantity / 100
          }
        }
      })
    }
    return summe.toFixed(2)
  }

}