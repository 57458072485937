import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-watchinator',
  templateUrl: './watchinator.component.html',
  styleUrls: ['./watchinator.component.scss']
})
export class WatchinatorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
