import { Component, PLATFORM_ID, Inject, OnInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from './shared/services/data.service';
import { WarenkorbService } from './shared/services/warenkorb.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  
  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );
  
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoadingBarService, translate: TranslateService,
    private data: DataService,) {
    if (isPlatformBrowser(this.platformId)) {
      translate.setDefaultLang('de');
      translate.addLangs(['de', 'en']);
    }
  }

  ngOnInit() {
    console.log('%c Made by ❤️ from Pio Virzi ' +  '%c@Iwm Informationstechnik GmbH ☕️', 'background: #55cbcd; color: white;', 'background: #222021')
  }

}


