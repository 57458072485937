import { EasyBillApiService } from "../../services/api/easybill.api"
import { ConfigService } from "../../services/config.service"
import { WarenkorbProduktTypes } from "../enums/warenkorb-produkt-types.enum"
import { Bauteil } from "./bauteil.model"
import { WarenkorbProdukt } from "./warenkorb-produkt.model"

export class WarenkorbWatchinatorProdukt extends WarenkorbProdukt {
    
    constructor(private config: ConfigService, api: EasyBillApiService) {
        super(config.WatchinatorArtikelNummer, api)
        this.type = WarenkorbProduktTypes.WATCHINATOR_UHR
        this.title = "Individuelle Memories-Watches Uhr"
    }

    id: string;
    konfiguriertAm: Date = null;

    title: string
    bandId: string
    gehaeuseId: string
    zeigerId: string
    ziffernblattId: string

    band: Bauteil
    gehaeuse: Bauteil
    zeiger: Bauteil
    ziffernblatt: Bauteil

    foto_b64: string
    entwurf_b64: string
    vorschau_b64: string
    dokumentFotoId: string
    dokumentEntwurfId: string
    dokumentVorschauId: string
}