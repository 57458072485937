import { Injectable, HostListener, Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from './storage.service';

@Injectable({
	providedIn: 'root'
})

export class AuthService {
	constructor(
    private storage: StorageService,
    private toast: ToastrService) {
	}

  public isLoggedIn() {
    var user = this.storage.getUser();
    var token = this.storage.getToken();

    if(user == null || token == null)
      return false;
    else
      return true;
  }

  getTokenString() {
    var token = this.storage.getToken();
    if(token != null)
      return token;
    else
      return null;
  }

  logout() {
    this.storage.setToken(null);
    this.storage.setUser(null);
    this.toast.success("Du hast dich erfolgreich ausgeloggt!");
  }
}
