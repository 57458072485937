import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { take } from "rxjs/operators";
import { Rechtstext } from "../../models/admin/rechtstext.model";
import { Bauteil } from "../../models/business/bauteil.model";
import { Warenkorb } from "../../models/business/warenkorb.model";
import { BauteilTypes } from "../../models/enums/bauteil-types.enum";
import { ConfigService } from "../config.service";
import { StorageService } from "../storage.service";

@Injectable({
  providedIn: "root",
})
export class WarenkorbApiService {
  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private storage: StorageService,
  ) {

  }

  public getById(id: string): Observable<any> {
    if(id != null)
    {
      var subject = new Subject<any>();
      this.http
        .get<any>(this.config.ApiUrl + "/warenkorb" + "/id", {
          params: {
            id: id
          },
          observe: "response",
          responseType: "json",
        })
        .pipe(take(1))
        .subscribe(
          (result) => {
            if(result.ok)
            {
              var r = Object.assign(new Warenkorb(), result.body)
              subject.next(r);
            }
            else
              subject.next(null);
          },
          (error) => {
            console.log("Error", error);
            subject.next(null);
          }
        );

      return subject.asObservable();
    }
  }


  public save(warenkorb: Warenkorb): Observable<any> {
    var subject = new Subject<any>();

    this.http
      .post<any>(this.config.ApiUrl + "/warenkorb", warenkorb, {
        observe: "response",
        responseType: "json",
      })
      .pipe(take(1))
      .subscribe(
        (result) => {
          var r = Object.assign(new Warenkorb(), result.body);
          subject.next(r);
        },
        (error) => {
          console.log("Error", error);
          subject.next(null);
        }
      );

    return subject.asObservable();
  }

  public done(id: string): Observable<any> {
    var subject = new Subject<any>();
    this.http.get<any>(this.config.ApiUrl + "/warenkorb" + "/done", {
          params: {
            id: id
          },
          observe: "response",
          responseType: "json",
        })
      .pipe(take(1))
      .subscribe(
        (result) => {
          subject.next(true);
        },
        (error) => {
          console.log("Error", error);
          subject.next(false);
        }
      );
    return subject.asObservable();
  }

  
  public deleteById(id: string): Observable<any> {
    var subject = new Subject<any>();
    this.http
      .delete<any>(this.config.ApiUrl + "/warenkorb" + "/id", {
        params: {
          id: id
        },
        observe: "response",
        responseType: "json",
      })
      .pipe(take(1))
      .subscribe(
        (result) => {
          if(result.ok)
          {
            subject.next(true)
          }
          else
            subject.next(false);
        },
        (error) => {
          console.log("Error", error);
          subject.next(false);
        }
      );

    return subject.asObservable();
  }
}
