import { Injectable, HostListener, Inject } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { Rechtstext } from '../models/admin/rechtstext.model';
import { User } from '../models/auth/user.model';
import { WarenkorbProdukt } from '../models/business/warenkorb-produkt.model';
import { WarenkorbWatchinatorProdukt } from '../models/business/warenkorb-watchinator-produkt.model';
import { LocalStorageTypes } from '../models/enums/localstorage.enum';
import { CryptoService } from './crypto.service';

@Injectable({
	providedIn: 'root'
})

export class StorageService {
	constructor() {

	}

  setWatchinator(watchinator: WarenkorbWatchinatorProdukt) {
    localStorage.setItem(LocalStorageTypes.WATCHINATOR_STATE, CryptoService.Encrypt(JSON.stringify(watchinator)))
  }

  getWatchinator() {
    if(localStorage.getItem(LocalStorageTypes.WATCHINATOR_STATE) != null)
      return JSON.parse(CryptoService.Decrypt(localStorage.getItem(LocalStorageTypes.WATCHINATOR_STATE)))
    else
      return null
  }

  setWarenkorbId(id: string) {
    localStorage.setItem(LocalStorageTypes.WARENKORB, id)
  }

  resetWarenkorbId()
  {
    localStorage.removeItem(LocalStorageTypes.WARENKORB)
  }

  getWarenkorbId() {
    if(localStorage.getItem(LocalStorageTypes.WARENKORB) == null)
      return null
    else
      return localStorage.getItem(LocalStorageTypes.WARENKORB)
  }

  resetWarenkorb() {
    localStorage.setItem(LocalStorageTypes.WARENKORB, CryptoService.Encrypt(JSON.stringify([])))
  }


  setToken(token: string){
    localStorage.setItem(LocalStorageTypes.TOKEN, JSON.stringify(token));
  }

  getToken(): string {
    return JSON.parse(localStorage.getItem(LocalStorageTypes.TOKEN));
  }

  setUser(user: User){
    localStorage.setItem(LocalStorageTypes.USER, JSON.stringify(user));
  }

  getUser(): User {
    return Object.assign(new User(), JSON.parse(localStorage.getItem(LocalStorageTypes.USER)));
  }

  setRechtstexte(value: Rechtstext[]){
    localStorage.setItem(LocalStorageTypes.RECHTSTEXTE, CryptoService.Encrypt(JSON.stringify(value)));
  }

  getRechtstexte(): Rechtstext[] {
    if(localStorage.getItem(LocalStorageTypes.RECHTSTEXTE) != null)
      return JSON.parse(CryptoService.Decrypt(localStorage.getItem(LocalStorageTypes.RECHTSTEXTE)));
    else
      return [];
  }
}
