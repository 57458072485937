import { EasyBillDocumentPosition } from "./EasyBillDocumentPosition"


export class EasyBillDocument {
  // address: EasyBillAddress
  attachment_ids: number[] = []
  // label_address: EasyBillAddress
  amount: number
  amount_net: number
  bank_debit_form: string
  billing_country: string
  calc_vat_from: string
  cancel_id: number
  cash_allowance: number
  cash_allowance_days: number
  cash_allowance_text: string
  contact_id: number
  contact_label: string
  contact_text: string
  created_at: string
  currency: string
  customer_id: number
  // customer_snapshot: EasyBillCustomer
  discount: string
  discount_type: string
  document_date: string
  due_date: string
  edited_at: string
  external_id: string
  replica_url: string
  grace_period: number
  due_in_days: number
  id: number
  is_archive: boolean
  is_draft: boolean
  is_replica: boolean
  is_cold: boolean
  coldstorage_due_date: string
  item_notes: string
  items: EasyBillDocumentPosition[] = []
  last_postbox_id: number
  login_id: number
  number: string
  order_number: string
  buyer_reference: string
  paid_amount: number
  paid_at: number
  pdf_pages: number
  pdf_template: string
  project_id: number
  ref_id: number
  shipping_country: string
  // status: EasyBillDocumentStatusTypes
  text: string
  text_prefix: string
  text_tax: string
  title: string
  type: string
  use_shipping_address: boolean
  vat_country: string
  vat_id: string
  fulfillment_country: string
  vat_option: string
}
