import { Injectable } from "@angular/core";
import { take } from "rxjs/operators";
import { RechtstexteApiService } from "./api/rechtstexte.api";
import { StorageService } from "./storage.service";

@Injectable({
  providedIn: "root",
})
export class DataService {
  constructor(
    private apiRechtstexte: RechtstexteApiService,
    private storage: StorageService
  ) {
    console.log("GETTING RECHTSTEXTE");
    this.getRechtstexte();
  }

  public getRechtstexte() {
    this.apiRechtstexte.getByApp().pipe(take(1)).subscribe(e => {
      if(e != null)
      {
        console.log("GOT RECHTSTEXTE");
        this.storage.setRechtstexte(e);
      }
    },
    error => {
      console.log(error);
    })
  }
}