<header class="sticky" [ngClass]="class" > <!-- [class.fixed]="stick && sticky" -->
  <div class="mobile-fix-option"></div>
  <div class="top-header" *ngIf="topbar">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="header-contact">
            <ul>
              <li><img class="ger-flag" src="../../../../assets/images/icon/germany.png">Fotouhren Made in Germany.</li>
              <li><i class="fa fa-phone" aria-hidden="true"></i>Kostenlose Hotline: 0221 94224394</li>
            </ul>
          </div>
        </div>
        <!-- <div class="col-lg-6 text-right">
          <ul class="header-dropdown">
            <li class="mobile-wishlist">
              <a [routerLink]="['/shop/wishlist']"><i class="fa fa-heart" aria-hidden="true"></i>Wunschliste</a>
            </li>
            <li class="onhover-dropdown mobile-account">
              <i class="fa fa-user" aria-hidden="true"></i> Account
              <ul class="onhover-show-div">
                <li>
                  <a *ngIf="!auth.isLoggedIn()" [routerLink]="['/account/login']">
                    Einloggen
                  </a>
                </li>
                <li>
                  <a *ngIf="!auth.isLoggedIn()" [routerLink]="['/account/register']">
                    Registrieren
                  </a>
                </li>
                <li>
                  <a routerLink="/account/dashboard" *ngIf="auth.isLoggedIn()">
                    Dashboard
                  </a>
                </li>
                <li>
                  <a (click)="logout()" *ngIf="auth.isLoggedIn()">
                    Ausloggen
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div> -->
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="main-menu">
          <div class="menu-left">
            <app-left-menu></app-left-menu>
            <div class="brand-logo">
              <a routerLink="/home" id="headerlogo">
                <img [src]="themeLogo" class="img-fluid" alt="logo">
              </a>
            </div>
          </div>
          <div class="menu-right pull-right">
            <div>
              <app-menu></app-menu>
            </div>
            <div>
              <app-settings></app-settings>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
