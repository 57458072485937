import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-rechtstexte',
  templateUrl: './rechtstexte.component.html',
  styleUrls: ['./rechtstexte.component.scss']
})
export class RechtstexteComponent implements OnInit {

  public url : any; 

  constructor(private router: Router) {  
    this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            this.url = event.url;
          }
    });
  }

  ngOnInit(): void {
  }

}
