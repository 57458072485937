import { Injectable } from "@angular/core";
import { WarenkorbProdukt } from "../models/business/warenkorb-produkt.model";
import { Warenkorb } from "../models/business/warenkorb.model";
import { ApplicationTypes } from "../models/enums/application-types.enum";
import { WarenkorbProduktTypes } from "../models/enums/warenkorb-produkt-types.enum";
import { EasyBillApiService } from "./api/easybill.api";
import { ConfigService } from "./config.service";

@Injectable({
  providedIn: "root",
})
export class ArtikelService {
  public DHL_EXPRESS_ARTIKEL: WarenkorbProdukt
  public DHL_ARTIKEL: WarenkorbProdukt
  public DHL_EXPRESS_ARTIKEL_EU: WarenkorbProdukt
  public DHL_ARTIKEL_EU: WarenkorbProdukt
 
  constructor(private config: ConfigService, private easyBillApi: EasyBillApiService) {
    this.DHL_ARTIKEL = new WarenkorbProdukt(this.config.DHLArtikelNummer, easyBillApi)
    this.DHL_ARTIKEL.title = "DHL Standard"
    this.DHL_ARTIKEL.type = WarenkorbProduktTypes.VERSAND
    
    this.DHL_EXPRESS_ARTIKEL = new WarenkorbProdukt(this.config.DHLExpressArtikelNummer, easyBillApi)
    this.DHL_EXPRESS_ARTIKEL.title = "DHL Express"
    this.DHL_EXPRESS_ARTIKEL.type = WarenkorbProduktTypes.VERSAND 
    
    this.DHL_ARTIKEL_EU = new WarenkorbProdukt(this.config.DHLAuslandArtikelNummer, easyBillApi)
    this.DHL_ARTIKEL_EU.title = "DHL Standard (EU)"
    this.DHL_ARTIKEL_EU.type = WarenkorbProduktTypes.VERSAND 
    
    this.DHL_EXPRESS_ARTIKEL_EU = new WarenkorbProdukt(this.config.DHLExpressAuslandArtikelNummer, easyBillApi)
    this.DHL_EXPRESS_ARTIKEL_EU.title = "DHL Express (EU)"
    this.DHL_EXPRESS_ARTIKEL_EU.type = WarenkorbProduktTypes.VERSAND 
  }
}