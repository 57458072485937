import { Component, OnInit, Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from "../../services/product.service";
import { Product } from "../../classes/product";
import { StorageService } from '../../services/storage.service';
import { WarenkorbService } from '../../services/warenkorb.service';
import { WarenkorbProdukt } from '../../models/business/warenkorb-produkt.model';
import { ConfigService } from '../../services/config.service';
import { WarenkorbProduktTypes } from '../../models/enums/warenkorb-produkt-types.enum';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  public products: WarenkorbProdukt[] = [];
  public length: number = 0;
  public search: boolean = false;

  
  public languages = [{ 
    name: 'Englisch',
    code: 'en'
  }, {
    name: 'Französisch',
    code: 'fr'
  }];

  public currencies = [{
    name: 'Euro',
    currency: 'EUR',
    price: 0.90 // price of euro
  }, {
    name: 'Rupees',
    currency: 'INR',
    price: 70.93 // price of inr
  }, {
    name: 'Pound',
    currency: 'GBP',
    price: 0.78 // price of euro
  }, {
    name: 'Dollar',
    currency: 'USD',
    price: 1 // price of usd
  }]
  
  public WarenkorbProduktTypes = WarenkorbProduktTypes;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private translate: TranslateService, public config: ConfigService,
    public productService: ProductService, private storage: StorageService, private warenkorb: WarenkorbService) {
    // this.productService.cartItems.subscribe(response => this.products = response);
    
    const a = this.warenkorb.warenkorbObserver().subscribe(e => {
      if(e != null)
        this.products = e.products
    } )

    // if(this.warenkorb != null && this.warenkorb.warenkorb != null)
    //   this.products = this.warenkorb.warenkorb.products
  }

  getWarenkorbCount(): number {
    return (this.products.filter(e => e.type != WarenkorbProduktTypes.VERSAND)).map(a => a.quantity).reduce((a, b) => a + b, 0);
  }

  ngOnInit(): void {
   
    // if(this.warenkorb.warenkorb == null)
    // {
    //   this.warenkorb.getWarenkorb().then(e => {
    //     this.products = this.warenkorb.warenkorb.products
    //   })
    // }
    // else {
    //   this.products = this.warenkorb.warenkorb.products
    // }
  }

  searchToggle(){
    this.search = !this.search;
  }

  changeLanguage(code){
    if (isPlatformBrowser(this.platformId)) {
      this.translate.use(code)
    }
  }

  get getTotal(): Observable<number> {
    return this.productService.cartTotalAmount();
  }


  getSumme(ignoreVersand:boolean = false) {
    return this.warenkorb.getSumme(ignoreVersand)
  }

  async removeItem(product: any) {
    await this.warenkorb.removeFromWarenkorbAsync(product)
  }

  changeCurrency(currency: any) {
    this.productService.Currency = currency
  }

}
