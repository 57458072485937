import { take } from "rxjs/operators"
import { EasyBillApiService } from "../../services/api/easybill.api"
import { EasyBillDocumentPosition } from "../easybill/EasyBillDocumentPosition"
import { WarenkorbProduktTypes } from "../enums/warenkorb-produkt-types.enum"

export class WarenkorbProdukt {
    constructor(artikelnummer: string, private api: EasyBillApiService) {
        this.artikelnummer = artikelnummer
        this.quantity = 1
        this.fetch()
    }

    fetch() {
        this.api.getPositionByNumber(this.artikelnummer).pipe(take(1)).subscribe(e => {
            this.easybill_position = e;
            console.log(e)
            this.api = null
        }, (err) => {
            console.log("ERROR", err)
        })
    }

    title: string
    price: number
    photo: string
    quantity: number = 1
    product_name: string
    artikelnummer: string
    type: WarenkorbProduktTypes
    easybill_position: EasyBillDocumentPosition
    vorschau_b64: string
}