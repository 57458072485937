import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { take } from "rxjs/operators";
import { User } from "../../models/auth/user.model";
import { ApplicationTypes } from "../../models/enums/application-types.enum";
import { RegisterResultTypes } from "../../models/enums/register-result-types.enum";
import { RegisterResult } from "../../models/results/register.result";
import { ConfigService } from "../config.service";
import { StorageService } from "../storage.service";

@Injectable({
  providedIn: "root",
})
export class AuthApiService {
  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private storage: StorageService,
  ) {

  }

  public login(user): Observable<any> {
    var subject = new Subject<any>();
    console.log(user);
    user.app = this.config.AppType;
    this.http
      .post<any>(this.config.ApiUrl + "/auth/login/", user)
      .pipe(take(1))
      .subscribe(
        (result) => {
          console.log(result);
          console.log(user);
          this.storage.setToken(result);
          subject.next(result);
        },
        (error) => {
          console.log("Error", error);
          subject.next(error.error);
        }
      );

    return subject.asObservable();
  }

  public getUser(user): Observable<any> {
    var subject = new Subject<any>();

    var headerParams = {
      Authorization: this.storage.getToken(),
    };

    var headers: HttpHeaders = new HttpHeaders(headerParams);

    this.http
      .post<string>(this.config.ApiUrl + "/auth/user", user, {
        headers: headers,
        observe: "response",
        responseType: "json",
      })
      .pipe(take(1))
      .subscribe(
        (result) => {
          console.log(result);
          this.storage.setUser(Object.assign(new User(), result.body));
          subject.next(result);
        },
        (error) => {
          console.log("Error", error);
          subject.next(null);
        }
      );

    return subject.asObservable();
  }

  public register(user): Observable<any> {
    var subject = new Subject<any>();

    var model: any = {};
    model.user = user;
    model.app = this.config.AppType;

    this.http
      .post<any>(this.config.ApiUrl + "/auth/register", model, {
        observe: "response",
        responseType: "json",
      })
      .pipe(take(1))
      .subscribe(
        (result) => {
          var resultModel = Object.assign(new RegisterResult(), result);
          console.log(result);

          // if(resultModel.typ == RegisterResultTypes.SUCCESS)
          // {
          //   this.storage.setUser(Object.assign(new User(), resultModel.user))
          // }

          subject.next(resultModel.body);
        },
        (error) => {
          console.log("Error", error);
          subject.next(null);
        }
      );

    return subject.asObservable();
  }

}
