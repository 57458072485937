import { v4 as uuid } from 'uuid';

export class Base {
  constructor() {
    this.erstelltAm = new Date()
    this.geaendertAm = new Date()
    this.erstelltVon = "System"
    this.geaendertVon = "System"
    this.id = uuid();
  }

  id: string;
  erstelltAm: Date;
  erstelltVon: string;
  geaendertAm: Date;
  geaendertVon: string;
}
