import { Base } from "../base/base.model";
import { ProduktTypes } from "../enums/produkt-types.enum";
import { LagerPosition } from "./lagerposition.model";

export class Produkt extends Base {

    bezeichnung: string;
    produkt_typ: string;
    price: number = 0.0;
    public type: ProduktTypes;
    lager: LagerPosition;
}
