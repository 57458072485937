import { NullVisitor } from "@angular/compiler/src/render3/r3_ast";
import { BauteilTypes } from "../enums/bauteil-types.enum";
import { ProduktTypes } from "../enums/produkt-types.enum";
import { Produkt } from "./produkt.model";
export class Bauteil extends Produkt {
    constructor() {
      super();
      this.produkt_typ = ProduktTypes.BAUTEIL;
    }

    watchinatorVorschauB64: string = null;
    beschreibung: string = null;
    fotoB64: string = null;
    bauteil_typ: BauteilTypes = BauteilTypes.BAND;
    kompatible_gehaeuse: Bauteil[] = [];

    dokumentWatchinatorId: string = null;
    dokumentFotoId: string = null;
}
