import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { take } from "rxjs/operators";
import { WarenkorbWatchinatorProdukt } from "../../models/business/warenkorb-watchinator-produkt.model";
import { Warenkorb } from "../../models/business/warenkorb.model";
import { ConfigService } from "../config.service";
import { StorageService } from "../storage.service";
import { EasyBillApiService } from "./easybill.api";

@Injectable({
  providedIn: "root",
})
export class WatchinatorProduktApiService {
  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private api: EasyBillApiService,
    private storage: StorageService,
  ) {

  }

  public getById(id: string): Observable<any> {
    if(id != null)
    {
      var subject = new Subject<any>();
      this.http
        .get<any>(this.config.ApiUrl + "/watchinator/produkt" + "/id", {
          params: {
            id: id
          },
          observe: "response",
          responseType: "json",
        })
        .pipe(take(1))
        .subscribe(
          (result) => {
            if(result.ok)
            {
              var r = Object.assign(new WarenkorbWatchinatorProdukt(this.config, this.api), result.body)
              subject.next(r);
            }
            else
              subject.next(null);
          },
          (error) => {
            console.log("Error", error);
            subject.next(null);
          }
        );

      return subject.asObservable();
    }
  }


  public save(watchinatorProdukt: WarenkorbWatchinatorProdukt): Observable<any> {
    var subject = new Subject<any>();

    this.http
      .post<any>(this.config.ApiUrl + "/watchinator/produkt", watchinatorProdukt, {
        observe: "response",
        responseType: "json",
      })
      .pipe(take(1))
      .subscribe(
        (result) => {
          var r = Object.assign(new WarenkorbWatchinatorProdukt(this.config, this.api), result.body);
          subject.next(r);
        },
        (error) => {
          console.log("Error", error);
          subject.next(null);
        }
      );

    return subject.asObservable();
  }

  // public deleteById(id: string): Observable<any> {
  //   var subject = new Subject<any>();
  //   this.http
  //     .delete<any>(this.config.ApiUrl + "/warenkorb" + "/id", {
  //       params: {
  //         id: id
  //       },
  //       observe: "response",
  //       responseType: "json",
  //     })
  //     .pipe(take(1))
  //     .subscribe(
  //       (result) => {
  //         if(result.ok)
  //         {
  //           subject.next(true)
  //         }
  //         else
  //           subject.next(false);
  //       },
  //       (error) => {
  //         console.log("Error", error);
  //         subject.next(false);
  //       }
  //     );

  //   return subject.asObservable();
  // }
}
