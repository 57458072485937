import { Base } from "../base/base.model";
import { Kunde } from "../business/kunde.model";
import { Token } from "./token.model";

export class User extends Base {
  vorname: string;
  email: string;
  name: string;
  // titel: string;
  username: string;
  password: string;
  // isAdmin: boolean = false;
  // rechnungen: Rechnung[];
  tokens: Token[];
  kunde: Kunde;
  // unternehmen: Unternehmen[];
}
